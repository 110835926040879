import {Avatar, AvatarStack, Box, Flex, Stack, Text, Tooltip} from 'sanity/ui'

export function PresenceStack() {
  return (
    <Tooltip
      content={
        <Stack padding={2} space={2}>
          <Flex align="center">
            <Avatar />
            <Box flex={1} marginLeft={2}>
              <Text size={1} weight="medium">
                John Goodman
              </Text>
            </Box>
          </Flex>
          <Flex align="center">
            <Avatar />
            <Box flex={1} marginLeft={2}>
              <Text size={1} weight="medium">
                John Goodman
              </Text>
            </Box>
          </Flex>
          <Flex align="center">
            <Avatar />
            <Box flex={1} marginLeft={2}>
              <Text size={1} weight="medium">
                John Goodman
              </Text>
            </Box>
          </Flex>
        </Stack>
      }
      placement="top"
    >
      <div>
        <AvatarStack>
          <Avatar color="blue" />
          <Avatar color="purple" />
          <Avatar color="magenta" />
        </AvatarStack>
      </div>
    </Tooltip>
  )
}
