import {SortablePosition} from './types'

const buffer = 4

function clamp(min: number, max: number, value: number) {
  return Math.min(max, Math.max(min, value))
}

function distance(value1: number, value2: number) {
  return Math.abs(value1 - value2)
}

export const findIndex = (index: number, yOffset: number, positions: SortablePosition[]) => {
  const len = positions.length
  const {top, height} = positions[index]
  const bottom = top + height

  let target = index

  // If moving down
  if (yOffset > 0) {
    const nextItem = positions[index + 1]

    if (nextItem === undefined) {
      return index
    }

    const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + buffer

    if (yOffset > swapOffset) {
      target = index + 1
    }
  }

  // If moving up
  if (yOffset < 0) {
    const prevItem = positions[index - 1]

    if (prevItem === undefined) {
      return index
    }

    const prevBottom = prevItem.top + prevItem.height
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer

    if (yOffset < 0 - swapOffset) {
      target = index - 1
    }
  }

  return clamp(0, len, target)
}
