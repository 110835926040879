import {Validation} from 'sanity/core'
import {TextInput} from 'sanity/ui'
import {FormField} from '../../../components'

export function StringField({
  title,
  description,
  validation,
}: {
  title?: string
  description?: string
  validation?: Validation[]
}) {
  return (
    <FormField description={description} title={title} validation={validation}>
      <TextInput name="todo" />
    </FormField>
  )
}
