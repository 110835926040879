import {AxisBox2D, BoxDelta, motion, useDragControls} from 'framer-motion'
import {Preview, Validation} from 'sanity/core'
import {
  AddIcon,
  DocumentsIcon,
  DragHandleIcon,
  EditIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from 'sanity/icons'
import {Box, Button, Card, Flex, Layer, Menu, MenuButton, MenuItem, Stack} from 'sanity/ui'
import {Sortable, useSortableItem} from 'sanity/ui-lab'
import {FormField} from '../../../components'

export function ArrayField({
  title,
  description,
  validation,
}: {
  title?: string
  description?: string
  validation?: Validation[]
}) {
  return (
    <FormField description={description} title={title} validation={validation}>
      <Sortable space={2}>
        <ArrayFieldItem id="a" />
        <ArrayFieldItem id="b" />
      </Sortable>
      <Stack marginTop={2} space={2}>
        <Button icon={AddIcon} mode="ghost" radius={1} text="Add item" />
      </Stack>
    </FormField>
  )
}

function ArrayFieldItem({id}: {id: string}) {
  const {
    dragging,
    tapping,
    onDragStart,
    onDragEnd,
    onHoverStart,
    onHoverEnd,
    onTapStart,
    onTap,
    onTranslateY,
    ref,
  } = useSortableItem()

  const dragControls = useDragControls()

  const handleViewportBoxUpdate = (_: AxisBox2D, delta: BoxDelta) => {
    onTranslateY(delta.y.translate)
  }

  return (
    <Layer as="li" style={{padding: 1, margin: '-1px 0'}} zOffset={dragging ? 100 : 0}>
      <motion.div
        drag="y"
        dragControls={dragControls}
        dragListener={false}
        id={id}
        initial={false}
        layout
        onDragStart={onDragStart}
        onDragEnd={() => {
          onDragEnd()
          onTap()
        }}
        onHoverStart={onHoverStart}
        onHoverEnd={onHoverEnd}
        onViewportBoxUpdate={handleViewportBoxUpdate}
        ref={ref}
        style={{scale: dragging || tapping ? 1.025 : 1}}
      >
        <Card
          padding={1}
          radius={1}
          shadow={dragging || tapping ? 2 : 1}
          style={{transition: 'box-shadow 150ms'}}
        >
          <Flex>
            <Box marginRight={1}>
              <motion.div onTapStart={onTapStart} onTap={onTap}>
                <Button
                  icon={DragHandleIcon}
                  mode="bleed"
                  onMouseDown={(e) => dragControls.start(e)}
                  paddingX={2}
                  paddingY={3}
                  selected={dragging}
                  style={{cursor: 'grab'}}
                />
              </motion.div>
            </Box>
            <Box flex={1}>
              <Preview title="Test" description="Hello, world" />
            </Box>
            <Box marginLeft={1}>
              <MenuButton
                button={
                  <Button icon={EllipsisVerticalIcon} mode="bleed" paddingX={2} paddingY={3} />
                }
                id="trash-button"
                menu={
                  <Menu>
                    <MenuItem icon={EditIcon} text="Edit" />
                    <MenuItem icon={DocumentsIcon} text="Duplicate" />
                    <MenuItem icon={TrashIcon} text="Delete" tone="critical" />
                  </Menu>
                }
                portal
              />
            </Box>
          </Flex>
        </Card>
      </motion.div>
    </Layer>
  )
}
