import {Validation} from 'sanity/core'
import {GenerateIcon} from 'sanity/icons'
import {Box, Button, TextInput} from 'sanity/ui'
import {FormField} from '../../../components'

export function SlugField({
  title,
  description,
  validation,
}: {
  title?: string
  description?: string
  validation?: Validation[]
}) {
  return (
    <FormField description={description} title={title} validation={validation}>
      <TextInput
        suffix={
          <Box padding={1}>
            <Button
              icon={GenerateIcon}
              mode="bleed"
              padding={2}
              style={{display: 'block'}}
              title="Generate"
            />
          </Box>
        }
      />
    </FormField>
  )
}
