import {Box, Card, Grid, Stack, Text} from 'sanity/ui'

export function FormFieldSet({children}: {children: React.ReactNode}) {
  return (
    <Box>
      <Box paddingY={2}>
        <Stack space={2}>
          <Text size={1} weight="semibold">
            Title
          </Text>
          <Text muted size={1}>
            Description
          </Text>
        </Stack>
      </Box>
      <Card borderLeft marginTop={1} paddingLeft={3}>
        <Grid gap={5}>{children}</Grid>
      </Card>
    </Box>
  )
}
