import {AxisBox2D, BoxDelta, motion} from 'framer-motion'
import {useSortableItem} from './useSortableItem'

interface SortableItemProps {
  children?: React.ReactNode
  id: string
}

export function SortableItem(props: SortableItemProps) {
  const {
    onDragStart,
    onDragEnd,
    onHoverStart,
    onHoverEnd,
    onTapStart,
    onTap,
    onTranslateY,
    ref,
  } = useSortableItem()

  const {children} = props

  const handleViewportBoxUpdate = (_: AxisBox2D, delta: BoxDelta) => {
    // console.log('delta', delta.y.translate)
    onTranslateY(delta.y.translate)
  }

  return (
    <motion.div
      drag="y"
      initial={false}
      layout
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      onTapStart={onTapStart}
      onTap={onTap}
      onViewportBoxUpdate={handleViewportBoxUpdate}
      ref={ref}
      whileTap={{scale: 1.025}}
    >
      {children}
    </motion.div>
  )
}
