import {Preview, ValidationMenu} from 'sanity/core'
import {
  ArrayField,
  BooleanField,
  FormField,
  FormFieldSet,
  FormFieldHeader,
  FormFieldHeaderTitle,
  PresenceStack,
  ReferenceField,
  SlugField,
  StringField,
} from 'sanity/field'
import {ErrorOutlineIcon} from 'sanity/icons'
import {Box, Button, Card, Code, Container, Flex, Grid, Label, MenuButton, Stack} from 'sanity/ui'
import {CircularProgress, DatePicker, LinearProgress, Sortable, SortableItem} from 'sanity/ui-lab'
import {DragExample} from './dragExample'

function ComponentPreview({children, name}: {children: React.ReactNode; name: string}) {
  return (
    <Stack space={2}>
      <Code size={1}>{`<${name} />`}</Code>
      <Card shadow={1}>{children}</Card>
    </Stack>
  )
}

function ComponentList() {
  return (
    <>
      <ComponentPreview name="CircularProgress">
        <Box padding={4}>
          <CircularProgress value={50} />
        </Box>
      </ComponentPreview>

      <ComponentPreview name="LinearProgress">
        <Box padding={4}>
          <LinearProgress value={10} />
        </Box>
      </ComponentPreview>

      <ComponentPreview name="DatePicker">
        <DatePicker />
      </ComponentPreview>

      <ComponentPreview name="DragExample">
        <DragExample />
      </ComponentPreview>

      <ComponentPreview name="FormFieldSet">
        <FormFieldSet>
          <Card tone="transparent">1</Card>
          <Card tone="transparent">2</Card>
        </FormFieldSet>
      </ComponentPreview>

      <ComponentPreview name="ValidationMenu">
        <ValidationMenu />
      </ComponentPreview>

      <ComponentPreview name="ValidationMenu">
        <Flex>
          <Box flex={1} />
          <Box padding={2}>
            <MenuButton
              button={<Button icon={ErrorOutlineIcon} mode="bleed" tone="critical" />}
              id="validation-menu-button"
              menu={<ValidationMenu />}
            />
          </Box>
        </Flex>
      </ComponentPreview>

      <ComponentPreview name="Preview">
        <Preview
          title="Title"
          description="Description"
          validation={[{type: 'warning', label: 'Warning'}]}
        />
      </ComponentPreview>

      <ComponentPreview name="FormField">
        <FormField
          title="Title"
          description="Description"
          presence={<PresenceStack />}
          validation={[{type: 'warning', label: 'Warning'}]}
        >
          <Card padding={3} tone="transparent">
            <Code>[slot: children]</Code>
          </Card>
        </FormField>
      </ComponentPreview>

      <ComponentPreview name="FormFieldHeader">
        <FormFieldHeader
          title="Title"
          description="Description"
          validation={[{type: 'warning', label: 'Warning'}]}
        />
      </ComponentPreview>

      <ComponentPreview name="FormFieldHeaderTitle">
        <FormFieldHeaderTitle
          title="Title"
          description="Description"
          validation={[{type: 'warning', label: 'Warning'}]}
        />
      </ComponentPreview>

      <ComponentPreview name="Sortable">
        <Sortable>
          <SortableItem id="a">
            <Card padding={3} radius={3} tone="transparent">
              a
            </Card>
          </SortableItem>
          <SortableItem id="b">
            <Card padding={3} radius={3} tone="transparent">
              b
            </Card>
          </SortableItem>
        </Sortable>
      </ComponentPreview>
    </>
  )
}

function FormFieldExamples() {
  return (
    <>
      <StringField
        title="String"
        description="With error and warning"
        validation={[
          {type: 'warning', label: 'Warning'},
          {type: 'error', label: 'Warning'},
        ]}
      />

      <SlugField title="Slug" validation={[{type: 'warning', label: 'Warning'}]} />

      <BooleanField
        title="Boolean"
        description="With error and warning"
        validation={[
          {type: 'warning', label: 'Warning'},
          {type: 'error', label: 'Warning'},
        ]}
      />

      <ArrayField
        title="Array"
        description="With error and warning"
        validation={[
          {type: 'warning', label: 'Warning'},
          {type: 'error', label: 'Warning'},
        ]}
      />

      <ReferenceField
        title="Reference"
        description="With error and warning"
        validation={[
          {type: 'warning', label: 'Warning'},
          {type: 'error', label: 'Warning'},
        ]}
      />

      <FormFieldSet>
        <StringField
          title="String"
          description="With error and warning"
          validation={[
            {type: 'warning', label: 'Warning'},
            {type: 'error', label: 'Warning'},
          ]}
        />

        <SlugField title="Slug" validation={[{type: 'warning', label: 'Warning'}]} />

        <BooleanField
          title="Boolean"
          description="With error and warning"
          validation={[
            {type: 'warning', label: 'Warning'},
            {type: 'error', label: 'Warning'},
          ]}
        />
      </FormFieldSet>
    </>
  )
}

export function App() {
  return (
    <Flex height="fill">
      <Card flex={1} overflow="auto" tone="transparent">
        {/* <Container width={0}> */}
        <Box padding={4}>
          <Label size={1}>Components</Label>
        </Box>
        <Grid autoFlow="row dense" columns={[1, 1, 1, 2, 3]} gap={5} padding={4}>
          <ComponentList />
        </Grid>
        {/* </Container> */}
      </Card>

      <Card flex={1} overflow="auto" sizing="border">
        <Box padding={4}>
          <Label size={1}>Examples</Label>
        </Box>
        <Container width={0}>
          <Grid gap={5} padding={4}>
            <FormFieldExamples />
          </Grid>
        </Container>
      </Card>
    </Flex>
  )
}
