import {AxisBox2D, BoxDelta, motion, useDragControls} from 'framer-motion'
import {DragHandleIcon} from 'sanity/icons'
import {Box, Button, Card, Code, Flex} from 'sanity/ui'
import {Sortable, useSortableItem} from 'sanity/ui-lab'

export function DragExample() {
  return (
    <Card overflow="hidden" padding={6}>
      <Sortable space={2}>
        <DraggableItem id="a" />
        <DraggableItem id="b" />
        <DraggableItem id="c" />
        <DraggableItem id="d" />
      </Sortable>
    </Card>
  )
}

function DraggableItem({id}: {id: string}) {
  const {
    dragging,
    tapping,
    onDragStart,
    onDragEnd,
    onHoverStart,
    onHoverEnd,
    onTapStart,
    onTap,
    onTranslateY,
    ref,
  } = useSortableItem()

  const dragControls = useDragControls()

  const handleViewportBoxUpdate = (_: AxisBox2D, delta: BoxDelta) => {
    onTranslateY(delta.y.translate)
  }

  return (
    <motion.div
      drag="y"
      dragControls={dragControls}
      dragListener={false}
      id={id}
      initial={false}
      layout
      onDragStart={onDragStart}
      onDragEnd={() => {
        onDragEnd()
        onTap()
      }}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      onViewportBoxUpdate={handleViewportBoxUpdate}
      ref={ref}
      style={{scale: dragging || tapping ? 1.025 : 1}}
    >
      <Card
        radius={3}
        shadow={dragging || tapping ? 2 : 1}
        style={{transition: 'box-shadow 150ms'}}
      >
        <Flex>
          <Box padding={1} paddingRight={0}>
            <motion.div onTapStart={onTapStart} onTap={onTap}>
              <Button
                icon={DragHandleIcon}
                mode="bleed"
                onMouseDown={(e) => dragControls.start(e)}
                paddingX={2}
                paddingY={3}
                selected={dragging}
                style={{cursor: 'grab'}}
              />
            </motion.div>
          </Box>
          <Box padding={1}>
            <Box paddingX={1} paddingY={3}>
              <Code>Area</Code>
            </Box>
          </Box>
        </Flex>
      </Card>
    </motion.div>
  )
}
