import {forwardRef} from 'react'
import {ErrorOutlineIcon, WarningOutlineIcon} from 'sanity/icons'
import {Box, Flex, Menu, MenuItem, Stack, Text} from 'sanity/ui'

export const ValidationMenu = forwardRef((props: any, ref) => {
  return (
    <Menu {...props} ref={ref}>
      <MenuItem tone="critical">
        <Flex padding={3}>
          <Box marginRight={3}>
            <Text>
              <ErrorOutlineIcon />
            </Text>
          </Box>
          <Box flex={1}>
            <Stack space={2}>
              <Text weight="medium">Validation</Text>
              <Text muted size={1}>
                Validation
              </Text>
            </Stack>
          </Box>
        </Flex>
      </MenuItem>
      <MenuItem tone="caution">
        <Flex padding={3}>
          <Box marginRight={3}>
            <Text>
              <WarningOutlineIcon />
            </Text>
          </Box>
          <Box flex={1}>
            <Stack space={2}>
              <Text weight="medium">Validation</Text>
              <Text muted size={1}>
                Validation
              </Text>
            </Stack>
          </Box>
        </Flex>
      </MenuItem>
    </Menu>
  )
})
