import move from 'array-move'
import {useCallback, useEffect, useRef, useState} from 'react'
import {isElement, isFragment} from 'react-is'
import {Stack} from 'sanity/ui'
import {findIndex} from './helpers'
import {SortableItemContext} from './sortableItemContext'
import {SortablePosition} from './types'

export function childrenToElementArray(children: React.ReactNode): Array<React.ReactElement> {
  const childrenArray = Array.isArray(children) ? children : [children]

  return childrenArray.filter(
    (node) => isElement(node) || isFragment(node)
  ) as Array<React.ReactElement>
}

export function Sortable({
  children,
  space,
}: {
  children: React.ReactElement[]
  space?: number | number[]
}) {
  const elements = childrenToElementArray(children)
  const initialKeys: string[] = elements.map((el) => el.props.id)

  const [keys, setKeys] = useState<string[]>(initialKeys)

  const nodes: React.ReactElement[] = keys.map((key) => {
    return elements.find((e) => e.props.id === key) as any
  })

  // We need to collect an array of height and position data for all of this component's
  // `Item` children, so we can later us that in calculations to decide when a dragging
  // `Item` should swap places with its siblings.
  const positions = useRef<SortablePosition[]>([])

  const setPositionAtIndex = useCallback((pos: SortablePosition, index: number) => {
    positions.current[index] = pos
  }, [])

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const updateOrder = (index: number, dragOffset: number) => {
    const targetIndex = findIndex(index, dragOffset, positions.current)
    if (targetIndex !== index) setKeys(move(keys, index, targetIndex))
  }

  return (
    <Stack as="ul" space={space}>
      {/* <Code>{JSON.stringify(keys, null, 2)}</Code> */}

      {nodes.map((node, index) => (
        <SortableItemProvider
          id={node.props.id}
          index={index}
          key={node.props.id}
          setPositionAtIndex={setPositionAtIndex}
          updateOrder={updateOrder}
        >
          {node}
        </SortableItemProvider>
      ))}
    </Stack>
  )
}

function SortableItemProvider({
  children,
  id,
  index,
  setPositionAtIndex,
  updateOrder,
}: {
  children: React.ReactElement
  id: string
  index: number
  setPositionAtIndex: (pos: SortablePosition, index: number) => void
  updateOrder: (index: number, dragOffset: number) => void
}) {
  // We'll use a `ref` to access the DOM element that the `motion.li` produces.
  // This will allow us to measure its height and position, which will be useful to
  // decide when a dragging element should switch places with its siblings.
  const ref = useRef<HTMLDivElement | null>(null)

  const [dragging, setDragging] = useState(false)
  const [hovering, setHovering] = useState(false)
  const [tapping, setTapping] = useState(false)

  const handleDragStart = useCallback(() => setDragging(true), [])
  const handleDragEnd = useCallback(() => setDragging(false), [])

  const handleHoverStart = useCallback(() => setHovering(true), [])
  const handleHoverEnd = useCallback(() => setHovering(false), [])

  const handleTapStart = useCallback(() => setTapping(true), [])
  const handleTap = useCallback(() => setTapping(false), [])

  const handleTranslateY = useCallback(
    (translateY: number) => {
      if (!dragging) return
      updateOrder(index, translateY)
    },
    [dragging, index, updateOrder]
  )

  // Update the measured position of the item so we can calculate when we should rearrange.
  useEffect(() => {
    if (!ref.current) return
    setPositionAtIndex({height: ref.current.offsetHeight, top: ref.current.offsetTop}, index)
  }, [index, setPositionAtIndex])

  return (
    <SortableItemContext.Provider
      value={{
        dragging,
        hovering,
        tapping,
        onDragStart: handleDragStart,
        onDragEnd: handleDragEnd,
        onHoverStart: handleHoverStart,
        onHoverEnd: handleHoverEnd,
        onTapStart: handleTapStart,
        onTap: handleTap,
        onTranslateY: handleTranslateY,
        ref,
      }}
    >
      <li style={{zIndex: dragging ? 2 : 1}}>{children}</li>
    </SortableItemContext.Provider>
  )
}
