import React from 'react'
import ReactDOM from 'react-dom'
import {studioTheme} from 'sanity/core'
import {LayerProvider, ThemeColorProvider, ThemeProvider, usePrefersDark} from 'sanity/ui'
import {App} from './app'
import {GlobalStyle} from './globalStyle'

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
)

function Root() {
  const prefersDark = usePrefersDark()
  const scheme = prefersDark ? 'dark' : 'light'

  return (
    <ThemeProvider scheme={scheme} theme={studioTheme}>
      <ThemeColorProvider tone="transparent">
        <GlobalStyle />
      </ThemeColorProvider>

      <LayerProvider>
        <App />
      </LayerProvider>
    </ThemeProvider>
  )
}
