import {Validation} from 'sanity/core'
import {Avatar, AvatarStack, Box, Card, Checkbox, Flex} from 'sanity/ui'
import {FormFieldHeaderTitle} from '../../../components'

export function BooleanField({
  title,
  description,
  validation,
}: {
  title?: string
  description?: string
  validation?: Validation[]
}) {
  return (
    <Card radius={1} shadow={1}>
      <Flex padding={3}>
        <Box>
          <Checkbox style={{display: 'block'}} />
        </Box>
        <Box flex={1} marginLeft={3}>
          <div style={{padding: '3px 0'}}>
            <FormFieldHeaderTitle title={title} description={description} validation={validation} />
          </div>
        </Box>
        <Box marginLeft={3}>
          <AvatarStack maxLength={1} style={{margin: '-3px'}}>
            <Avatar />
            <Avatar />
            <Avatar />
          </AvatarStack>
        </Box>
      </Flex>
    </Card>
  )
}
