import {Validation} from 'sanity/core'
import {SearchIcon} from 'sanity/icons'
import {Autocomplete} from 'sanity/ui'
import {FormField} from '../../../components'

export function ReferenceField({
  title,
  description,
  validation,
}: {
  title?: string
  description?: string
  validation?: Validation[]
}) {
  return (
    <FormField title={title} description={description} validation={validation}>
      <Autocomplete
        icon={SearchIcon}
        id="reference"
        options={[{value: 'Oslo'}, {value: 'San Francisco'}, {value: 'New York'}]}
        radius={1}
      />
    </FormField>
  )
}
