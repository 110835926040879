import {createContext} from 'react'

interface SortableItemContextValue {
  // states
  dragging: boolean
  hovering: boolean
  tapping: boolean
  onDragStart: () => void
  onDragEnd: () => void
  onHoverStart: () => void
  onHoverEnd: () => void
  onTapStart: () => void
  onTap: () => void
  onTranslateY: (translateY: number) => void
  ref: React.MutableRefObject<HTMLDivElement | null>
}

export const SortableItemContext = createContext<SortableItemContextValue | null>(null)
