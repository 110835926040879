import {createElement} from 'react'
import {hues} from 'sanity/color'
import {Validation} from 'sanity/core'
import {ErrorOutlineIcon, WarningOutlineIcon} from 'sanity/icons'
import {Box, Flex, Stack, Text, Tooltip} from 'sanity/ui'

export function FormField({
  children,
  title,
  description,
  presence,
  validation = [],
}: {
  children: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  presence?: React.ReactNode
  validation?: Validation[]
}) {
  return (
    <Stack space={1}>
      <FormFieldHeader
        title={title}
        description={description}
        presence={presence}
        validation={validation}
      />
      <div>{children}</div>
    </Stack>
  )
}

export function FormFieldHeader({
  title,
  description,
  presence,
  validation = [],
}: {
  title?: React.ReactNode
  description?: React.ReactNode
  presence?: React.ReactNode
  validation?: Validation[]
}) {
  return (
    <Flex align="flex-end">
      <Box flex={1} paddingY={2}>
        <FormFieldHeaderTitle title={title} description={description} validation={validation} />
      </Box>
      {presence && <Box>{presence}</Box>}
    </Flex>
  )
}

export function FormFieldHeaderTitle({
  title,
  description,
  validation = [],
}: {
  title?: React.ReactNode
  description?: React.ReactNode
  validation?: Validation[]
}) {
  const warnings = validation.filter((v) => v.type === 'warning')
  const errors = validation.filter((v) => v.type === 'error')

  let validationIcon: React.ComponentType | null = null
  if (warnings.length > 0) validationIcon = WarningOutlineIcon
  if (errors.length > 0) validationIcon = ErrorOutlineIcon

  return (
    <Stack space={2}>
      <Flex>
        <Text weight="semibold" size={1}>
          {title || <em>Untitled</em>}
        </Text>
        {warnings.length > 0 && (
          <Tooltip
            content={
              <Box padding={2}>
                <Text muted size={1}>
                  Warning
                </Text>
              </Box>
            }
            placement="right"
          >
            <Box marginLeft={2}>
              <Text
                muted
                size={1}
                weight="semibold"
                style={{
                  color: warnings.length
                    ? errors.length
                      ? hues.red[500].hex
                      : hues.yellow[500].hex
                    : undefined,
                }}
              >
                {validationIcon && createElement(validationIcon)}
              </Text>
            </Box>
          </Tooltip>
        )}
      </Flex>
      {description && (
        <Text muted size={1}>
          {description}
        </Text>
      )}
    </Stack>
  )
}
