import {Box, Card, Flex, Stack, Text} from 'sanity/ui'
import {Validation} from '../types'

export function Preview({
  title,
  description,
  validation,
}: {
  title?: string
  description?: string
  validation?: Validation[]
}) {
  return (
    <Flex align="center">
      <Box marginRight={2}>
        <Card padding={3} radius={2} tone="transparent">
          <Text style={{width: 11}}>&nbsp;</Text>
        </Card>
      </Box>

      <Box flex={1}>
        <Stack space={2}>
          <Text muted={!title} size={1} weight={title ? 'medium' : 'regular'}>
            {title || <>Untitled</>}
          </Text>

          {description && (
            <Text muted size={1}>
              {description}
            </Text>
          )}
        </Stack>
      </Box>
    </Flex>
  )
}
