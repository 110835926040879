import {useState} from 'react'
import {Box, Card, Grid, Select, Text} from 'sanity/ui'

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate()
}

export function DatePicker() {
  const [date] = useState(new Date())
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const daysInMonth = getDaysInMonth(year, month)
  const days = Array.from(new Array(daysInMonth)).map((_, i) => i + 1)
  const _d = new Date(year, month, 1).getDay()

  return (
    <Card padding={2}>
      <Grid columns={3}>
        <Select>
          <option value={year}>{year}</option>
        </Select>
        <Select>
          <option value={month}>{month + 1}</option>
        </Select>
        <Select>
          <option value={day}>{day}</option>
        </Select>
      </Grid>
      <Grid columns={7} gap={2} marginTop={3}>
        <Box>
          <Text align="center" size={1} weight="bold">
            Sun
          </Text>
        </Box>
        <Box>
          <Text align="center" size={1} weight="bold">
            Mon
          </Text>
        </Box>
        <Box>
          <Text align="center" size={1} weight="bold">
            Tue
          </Text>
        </Box>
        <Box>
          <Text align="center" size={1} weight="bold">
            Wed
          </Text>
        </Box>
        <Box>
          <Text align="center" size={1} weight="bold">
            Thu
          </Text>
        </Box>
        <Box>
          <Text align="center" size={1} weight="bold">
            Fri
          </Text>
        </Box>
        <Box>
          <Text align="center" size={1} weight="bold">
            Sat
          </Text>
        </Box>

        {days.map((d, idx) => (
          <Box aria-selected={d === day} columnStart={idx === 0 ? _d + 1 : undefined} key={d}>
            <Card as="button" padding={2} shadow={1}>
              <Text align="center">{d}</Text>
            </Card>
          </Box>
        ))}
      </Grid>
    </Card>
  )
}
