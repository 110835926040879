import {Theme} from 'sanity/ui'
import {createGlobalStyle, css} from 'styled-components'

export const GlobalStyle = createGlobalStyle(({theme}: {theme: Theme}) => {
  const {base} = theme.sanity.color

  return css`
    html,
    body,
    #root {
      height: 100%;
    }

    html {
      background-color: ${base.bg};
      color: ${base.fg};
    }

    body {
      -webkit-font-smoothing: antialiased;
      margin: 0;
    }
  `
})
