import {useContext} from 'react'
import {SortableItemContext} from './sortableItemContext'

export function useSortableItem() {
  const sortableItem = useContext(SortableItemContext)

  if (!sortableItem) {
    throw new Error('SortableItem: missing context value')
  }

  return sortableItem
}
